<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: RIM-Nat / Plateforme 3D collaborative

    Author URL:
========================================================================================== -->


<template>
  <div>
    <vx-card :title="this.$t('loginRequired')">
      <login-auth0></login-auth0>
    </vx-card>
  </div>
</template>


<script>
import LoginAuth0 from './LoginAuth0.vue'

export default {
  components: {
    LoginAuth0
  },
  created () {
    // this.$store.dispatch('flushUser')
  }
}
</script>

<style lang="scss">
.login-tabs-container {
  min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
